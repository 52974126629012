import API from '@/service/admin-service'
let getToken = ()=>{ return localStorage.getItem('userAccessToken') }
export default {
    async loadComponent(componentid){
        try {
            var res = await API.getAdminApi('api/admin/component/loadcomponent', { userid : getToken(), componentid : componentid});
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)    
        }
    },
    async updateComponent(componentid, componentdata){
        let body ={
            componentid     : componentid,
            componentdata   : componentdata
        }
        try {
            var res = await API.postAdminApi('api/admin/component/update', { userid : getToken()},body);
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)    
        }
    }
   
}